import React, { useEffect, useState } from "react";
import FirstNavbar from "../dashboard/FirstNavbar";
import SideBar from "../dashboard/SideBar";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import baseURL from "../../Services/Url";
import { Modal, Button, Row, Form, Col, Spinner } from "react-bootstrap";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AgGridReact } from "ag-grid-react";
import { CSVLink, CSVDownload } from "react-csv";
import DatePicker from "react-datepicker"; // Import the date picker component
import "react-datepicker/dist/react-datepicker.css";


function ListRequirement() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [reloadPage, setReloadPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [rowData, setRowdata] = useState();

  const handleChangeDate = () => {
    const filteredResults = rowData.filter((item) => {
      if (startDate && endDate) {
        const itemDate = new Date(item.createdAt);
        const startTimestamp = startDate.setHours(0, 0, 0, 0);
        const endTimestamp = endDate.setHours(23, 59, 59, 999);
        return itemDate >= startTimestamp && itemDate <= endTimestamp;
      }
      return true;
    });

    setFilteredData(filteredResults);
  };

  const [updateMemberForm, setupdateMemberForm] = useState({
    heading: "",
    text: "",
    image: "",
  });
  const [file, setFile] = useState({
    image: "",
  });
  const [fileErrors, setFileErrors] = useState({
    image: "",
  });

  const [updateMemberFormErrors, setupdateMemberFormErrors] = useState({
    heading: "",
    text: "",
    image: "",
  });

  useEffect(() => {
    productsListApi();
  }, [reloadPage]);

  /** Product List **/
  const productsListApi = async () => {
    const data = await axios.post(
      "https://rr-api.realisticrealtors.com/api/rr/listing_requirment_list"
    );


    setRowdata(data?.data?.reverse());
  };



 
  /* list */
  const [deleleId, setDelelete] = useState("");
  const handleDelete = (ele) => {
    setShowDelete(true);
    setDelelete(ele);
  };

  const [showDelete, setShowDelete] = useState(false);
  const handleDeleteClose = () => {
    setShowDelete(false);
  };

  const handleDeleteClear = () => {
    setShowDelete(false);
  };

  const handleDeleteSubmit = () => {
    setLoader(true);
    axios
      .delete(`${baseURL}/${deleleId.id}`, {
        // headers: {
        //   Authorization: `Bearer ${token}`
        // },
      })
      .then((res) => {
        if (res.status == 200) {
          toast.success("Successfully Deleted");
          setLoader(false);
          setReloadPage(reloadPage + 1);
          setShowDelete(false);
        }
      })
      .catch((err) => {
        console.log(err, "222");
        if (err.response.status == 401) {
          toast.error("Token is Expaired");
          setLoader(false);
          setReloadPage(reloadPage + 1);
          setShowDelete(false);
        }
      });
  };

  const handleClose = () => {
    setShow(false);
  };

  /* update */
  const [updateID, setUpdateId] = useState("");
  const [show, setShow] = useState(false);

  /** handle Change **/
  const handleChange = (event) => {
    if (event.target.name == "image") {
      setFile({
        ...file,
        [event.target.name]: event?.target?.files[0],
      });
      setFileErrors({
        ...file,
        image: "",
      });
    } else {
      setupdateMemberForm({
        ...updateMemberForm,
        [event.target.name]: event.target.value,
      });
    }
    setupdateMemberFormErrors({
      ...updateMemberFormErrors,
      [event.target.name]: null,
    });
  };

  const handleUpdate = (ele) => {
    setShow(true);
    setupdateMemberForm({
      ...updateMemberForm,
      heading: ele.title,
      text: ele.description,
      image: "",
    });
    setShow(true);
    setUpdateId(ele);
  };

  /** Form Validation **/
  const handleValidation = () => {
    // const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    const textReg = /[A-Za-z]/;
    const newErros = {};
    const { heading, text } = updateMemberForm;
    const { image } = file;
    if (!heading) {
      newErros.heading = "please enter name";
    } else if (heading && !textReg.test(heading)) {
      newErros.heading = "numbers is not allowed";
    } else if (heading && heading.length > 50) {
      newErros.name = "name should be below 50 charecters";
    }

    if (!text) {
      newErros.text = "please enter disignation";
    } else if (text && !textReg.test(text)) {
      newErros.text = "numbers is not allowed";
    } else if (text && text.length > 50) {
      newErros.text = "disignation should be below 50 charecters";
    }
    if (!image) {
      newErros.image = "please select file";
    }
    return newErros;
  };

  const handleSubmit = () => {
    // const handleValidationObject = handleValidation()
    // if (Object.keys(handleValidationObject).length > 0) {
    //     setupdateMemberFormErrors(handleValidationObject)
    // } else {

    setLoader(true);
    let formData = new FormData();
    formData.append("category", "awards");

    if (updateMemberForm.heading) {
      formData.append("title", updateMemberForm.heading);
    }

    if (file.image) {
      formData.append("image", file.image);
    }

    if (updateMemberForm.text) {
      formData.append("description", updateMemberForm.text);
    }

    // const token = '32c2483430c53f2eec280c7f1ba826f697e78ac7'
    // headers: {
    //   Authorization: `Bearer ${token}`
    // },
    axios({
      url: `${baseURL}/${updateID.id}`,
      method: "PUT",
      data: formData,
    })
      .then((res) => {
        if (res.status == 200) {
          setReloadPage(reloadPage + 1);
          setShow(false);
          toast.success("Successfully Updated Meamber");
          setLoader(false);
          setupdateMemberForm({
            ...updateMemberForm,
            name: "",
            surname: "",
            disignation: "",
          });
          setFile({
            ...file,
            image: "",
          });
          setFileErrors({
            ...fileErrors,
            image: "",
          });
        }
      })
      .catch((err) => {
        if (err.response.status == 401) {
          setReloadPage(reloadPage + 1);
          setShow(false);
          toast.error("Token is Expaired");
          setLoader(false);
          setupdateMemberForm({
            ...updateMemberForm,
            name: "",
            surname: "",
            disignation: "",
          });
          setFile({
            ...file,
            image: "",
          });
          setFileErrors({
            ...fileErrors,
            image: "",
          });
        }
      });

    // }
  };

  /***** Pagenation Table******/
  const rowHeight = 50;
  const DefaultColumnSetting = {
    sortable: true,
    filter: true,
    autoHeight: true,
    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
    wrapText: true,

  };

  const [colDefs, setColDefs] = useState([
    {
      headerName: "Id",
      valueGetter: "node.rowIndex + 1",
      filter: true,
      lockPosition: true,
      width: 90,
      minWidth: 50,
      maxWidth: 150,
    },
    {
      headerName: "Property Type",
      filter: true,
      field: "property_type",
    },
    {
      headerName: "Requirement Type",
      filter: true,
      field: "requirement_type",
    },
    {
      headerName: "City",
      filter: true,
      field: "city",
    },
    {
      headerName: "Contact No",
      filter: true,
      field: "contact_no",
    },
    {
      headerName: "Email",
      filter: true,
      field: "email",
    },
    {
      headerName: "Description",
      filter: true,
      field: "description",
    },
    {
      headerName: "Date",
      filter: true,
      field: "createdAt",
      valueFormatter: (params) => {
        if (!params.value) return "";
        const date = new Date(params.value);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      },}
  ]);
  

  const handleClear = () => {
    setupdateMemberForm({
      ...updateMemberForm,
      heading: "",
      text: "",
      image: "",
    });
    setFile({
      ...file,
      image: "",
    });
    setFileErrors({
      ...fileErrors,
      image: "",
    });
  };
  console.log(rowData, "rowDatarowData");
  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        transition={Zoom}
        delay={500}
        limit={1}
      />
      <Modal show={showDelete} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Meamber</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Modal.Body>
            <div className="d-flex">
              <div style={{ marginLeft: "50px" }}>
                <Button variant="secondary" onClick={handleDeleteClear}>
                  Cancel
                </Button>
              </div>
              <div style={{ marginLeft: "150px" }}>
                {loader == false && (
                  <Button variant="danger" onClick={handleDeleteSubmit}>
                    Delete
                  </Button>
                )}
                {loader == true && (
                  <Spinner animation="border" variant="Primary" />
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12">
            <Row className="mb-6">
              <Form.Group as={Col}>
                <Form.Label> Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  name="heading"
                  value={updateMemberForm.heading}
                  onChange={handleChange}
                  autoComplete="off"
                />
                <span className="text-danger">
                  {updateMemberFormErrors.heading}
                </span>
              </Form.Group>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label>Product Text</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Product Text"
                name="text"
                value={updateMemberForm.text}
                onChange={handleChange}
                autoComplete="off"
              />
              <span className="text-danger">{updateMemberFormErrors.text}</span>
            </Form.Group>

            <Row className="mb-6">
              <Form.Group as={Col}>
                <Form.Label> Select Image</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Product Heading"
                  className="w-50"
                  name="image"
                  value={updateMemberForm.image}
                  onChange={handleChange}
                  autoComplete="off"
                />
                <span className="text-danger">
                  {updateMemberFormErrors.image}
                </span>
              </Form.Group>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClear}>
            Clear
          </Button>
          {loader == false && (
            <Button variant="primary" onClick={handleSubmit}>
              Upadete
            </Button>
          )}
          {loader == true && <Spinner animation="border" variant="Primary" />}
        </Modal.Footer>
      </Modal>
      <div>
        <div class="sidebar">
          <SideBar />
        </div>

        <div class="content">
          <div className="container ">
            <FirstNavbar />

            <div className="row my-2">
              <div className="col-md-4">
                <h3>
                  <b>List Requirement List</b>
                </h3>

              </div>
              <div className="row">

                <div className="col-md-5 my-2">
                  <label>Select Date Range: </label>
                  <div className="d-flex gap-2"> {/* Fix: Changed class name */}
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Start Date"
                    />
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="End Date"
                    />
                    <button className="btn btn-primary btn-sm mx-2" onClick={handleChangeDate}>
                      Filter
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                {rowData ?
                  <button style={{ fontWeight: "bold" }}>
                    <CSVLink
                      data={filteredData?.length !== 0 ? filteredData : rowData}

                      className="list-group-item"
                      filename={"List Requirement Form List"}
                    >
                      <span>Export to Excel </span>
                    </CSVLink>
                  </button> : ""
                }

              </div>
            </div>
   
            <div
              className="ag-theme-alpine"
              style={{ height: "80vh", width: "100%" }}
            >
              <AgGridReact
                rowHeight={rowHeight}

                columnDefs={colDefs}
                pagination={true}
                paginationPageSize={20}

                rowData={filteredData?.length !== 0 ? filteredData : rowData}


                width={150}

                defaultColDef={DefaultColumnSetting}
              />{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListRequirement;
