import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import baseURL from "../../Services/Url";
import SideBar from "../dashboard/SideBar";
import FirstNavbar from "../dashboard/FirstNavbar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function BlogAdd() {
  const [loader, setLoader] = useState(false);
  const [blogFormData, setBlogFormData] = useState({
    title: "",
    description: "",
    report_date: "",
    blog_type: "",
    author: "",
    image: null, // Add image field
  });
  const [formErrors, setFormErrors] = useState({
    title: "",
    description: "",
    report_date: "",
    blog_type: "",
    author: "",
    image: "", // Add image field
  });

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "image") {
      setBlogFormData({
        ...blogFormData,
        [name]: files[0],
      });
    } else {
      setBlogFormData({
        ...blogFormData,
        [name]: value,
      });
    }
    setFormErrors({
      ...formErrors,
      [name]: "", // Reset error when user starts typing
    });
  };
console.log(blogFormData,"pppppppppppppppppppppppppppppp")
  const handleDescriptionChange = (value) => {
    setBlogFormData({
      ...blogFormData,
      description: value,
    });
    setFormErrors({
      ...formErrors,
      description: "", // Reset error when user starts typing
    });
  };

  const handleSubmit = () => {
    const validationErrors = handleValidation();
    if (Object.keys(validationErrors).length > 0) {
      setFormErrors(validationErrors);
    } else {
      setLoader(true);
      let formData = new FormData();
      formData.append("title", blogFormData.title);
      formData.append("description", blogFormData.description);
      formData.append("report_date", blogFormData.report_date);
      formData.append("blog_type", blogFormData.blog_type);
      formData.append("author", blogFormData.author);
      formData.append("image", blogFormData.image); // Add image field
      formData.append("category", "blog"); // Set the category to "blog"

      axios({
        url: `${baseURL}create`,
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.status === 200) {

            setBlogFormData({
                title: "",
                description: "",
                report_date: "",
                blog_type: "",
                author: "",
                image: "",
            });
            console.log(blogFormData)
            toast.success("Blog created successfully");
            setLoader(false);
        
          }
        })
        .catch((err) => {
          console.error("Error creating blog:", err);
          toast.error("An error occurred while creating the blog");
          setLoader(false);
        });
    }
  };


  const handleValidation = () => {
    const { title, description, report_date, blog_type, author, image } = blogFormData;
    const newErrors = {};

    if (!title) {
      newErrors.title = "Please enter a title";
    }

    if (!description) {
      newErrors.description = "Please enter a description";
    }

    if (!report_date) {
      newErrors.report_date = "Please select a report date";
    }

    if (!blog_type) {
      newErrors.blog_type = "Please enter a blog type";
    }

    if (!author) {
      newErrors.author = "Please enter an author";
    }

    if (!image) {
      newErrors.image = "Please select an image";
    }

    return newErrors;
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        transition={Zoom}
        delay={500}
        limit={1}
      />
      <div>
        <div className="sidebar">
          <SideBar />
        </div>

        <div className="content">
          <div className="container">
            <FirstNavbar />
            <div className="row my-2">
<div className="col-md-4"></div>
<div className="col-md-3">
  <h3>
    <b>Add Blog List</b>
  </h3>
</div>
<div className="col-md-4 text-end">
  <button
    type="button"
    className="btn btn-outline-success mx-1"
    disabled
  >
    <Link to="/blog-add">Add Blog</Link>
  </button>
  <button type="button" className="btn btn-outline-success mx-1">
    <Link to="/blog-list"> Blog List</Link>
  </button>
</div>
</div>
            <div className="container mt-5">
              <Form>
                <Form.Group controlId="formTitle" className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={blogFormData.title}
                    onChange={handleChange}
                    placeholder="Enter title"
                  />
                  <span className="text-danger">{formErrors.title}</span>
                </Form.Group>

                <Form.Group controlId="formDescription" className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <ReactQuill
                    theme="snow"
                    value={blogFormData.description}
                    onChange={handleDescriptionChange}
                    placeholder="Enter description"
                  />
                  <span className="text-danger">{formErrors.description}</span>
                </Form.Group>

                <Form.Group controlId="formReportDate" className="mb-3">
                  <Form.Label>Report Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="report_date"
                    value={blogFormData.report_date}
                    onChange={handleChange}
                  />
                  <span className="text-danger">{formErrors.report_date}</span>
                </Form.Group>

                <Form.Group controlId="formBlogType" className="mb-3">
                  <Form.Label>Blog Type</Form.Label>
                  <Form.Control
                    type="text"
                    name="blog_type"
                    value={blogFormData.blog_type}
                    onChange={handleChange}
                    placeholder="Enter blog type"
                  />
                  <span className="text-danger">{formErrors.blog_type}</span>
                </Form.Group>

                <Form.Group controlId="formAuthor" className="mb-3">
                  <Form.Label>Author</Form.Label>
                  <Form.Control
                    type="text"
                    name="author"
                    value={blogFormData.author}
                    onChange={handleChange}
                    placeholder="Enter author"
                  />
                  <span className="text-danger">{formErrors.author}</span>
                </Form.Group>

                <Form.Group controlId="formImage" className="mb-3">
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    type="file"
                    name="image"
                    onChange={handleChange}
                  />
                  <span className="text-danger">{formErrors.image}</span>
                </Form.Group>

                <Button
                  variant="primary"
                  type="button"
                  onClick={handleSubmit}
                  disabled={loader}
                >
                  {loader ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogAdd;

