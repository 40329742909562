import React, { useEffect, useState } from "react";
import FirstNavbar from "../dashboard/FirstNavbar";
import SideBar from "../dashboard/SideBar";
import { Link } from "react-router-dom";
import axios from "axios";
import baseURL from "../../Services/Url";
import { Modal, Button, Row, Form, Col, Spinner } from "react-bootstrap";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AgGridReact } from "ag-grid-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function BlogList() {
  const [reloadPage, setReloadPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [rowData, setRowData] = useState([]);



  useEffect(() => {
    productsListApi();
  }, [reloadPage]);

  const productsListApi = () => {
    axios
      .get(`${baseURL}category/blog`)
      .then((res) => {
        if (res.status === 200) {
          setRowData(res.data);
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const [deleteId, setDeleteId] = useState("");
  const handleDelete = (ele) => {
    setShowDelete(true);
    setDeleteId(ele);
  };

  const [showDelete, setShowDelete] = useState(false);
  const handleDeleteClose = () => {
    setShowDelete(false);
  };

  const handleDeleteClear = () => {
    setShowDelete(false);
  };

  const handleDeleteSubmit = () => {
    setLoader(true);
    axios
      .delete(`${baseURL}/${deleteId.id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Successfully Deleted");
          setLoader(false);
          setReloadPage(reloadPage + 1);
          setShowDelete(false);
        }
      })
      .catch((err) => {
        console.log(err, "222");
        if (err.response.status === 401) {
          toast.error("Token is Expired");
          setLoader(false);
          setReloadPage(reloadPage + 1);
          setShowDelete(false);
        }
      });
  };

  const handleClose = () => {
    setShow(false);
  };

  const [updateID, setUpdateId] = useState("");
  const [show, setShow] = useState(false);


const handleUpdate = (ele) => {
  setShow(true);
  setUpdateBlogForm({
    title: ele.title,
    description: ele.description,
    report_date: ele.report_date,
    blog_type: ele.blog_type,
    author: ele.author,
    image: ele.image, 
  });
  setUpdateId(ele);
};

  
  const rowHeight = 50;

const DefaultColumnSetting = {
  sortable: true,
  filter: true,
  floatingFilter: true,
  flex: 1,
  resizable: true,
  minWidth: 120,
};

const colDefs = [
  {
    headerName: "Id",
    valueGetter: "node.rowIndex + 1",
    filter: true,
    lockPosition: true,
  },
  {
    headerName: "title",
    filter: true,
    field: "title",
  },
  {
    headerName: "description",
    filter: true,
    cellRendererFramework: (params) => (
      <div dangerouslySetInnerHTML={{ __html: params.data.description }} />
 
 
    ),
  },
  {
    headerName: "report date",
    filter: true,
    field: "report_date",
  },
  {
    headerName: "blog type",
    filter: true,
    field: "blog_type",
  },
  {
    headerName: "author",
    filter: true,
    field: "author",
  },
  {
    headerName: "Image",
    filter: false,
    cellRendererFramework: (params) => (
      <center>
        <img
          style={{ width: "50px", height: "50px" }}
          src={params.data.image}
        />
      </center>
    ),
  },
  {
    headerName: "Update",
    filter: false,
    cellRendererFramework: (params) => (
      <center onClick={() => handleUpdate(params.data)}>
        <button type="button" class="btn btn-outline-primary">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-pencil"
            viewBox="0 0 16 16"
          >
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
          </svg>
        </button>
      </center>
    ),
  },
  {
    headerName: "Delete",
    filter: false,
    cellRendererFramework: (params) => (
      <center onClick={() => handleDelete(params.data)}>
        <button type="button" class="btn btn-outline-danger">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-trash-fill"
            viewBox="0 0 16 16"
          >
            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
          </svg>
        </button>
      </center>
    ),
  },
  // ... (rest of your colDefs array)
];
const [updateBlogForm, setUpdateBlogForm] = useState({
    title: "",
    description: "",
    report_date: "",
    blog_type: "",
    author: "",
    image: null, // Assuming you're updating image as well
  });

  const [updateBlogFormErrors, setUpdateBlogFormErrors] = useState({
    title: "",
    description: "",
    report_date: "",
    blog_type: "",
    author: "",
    image: "",
  });

  const handleChange = (event) => {
    const { name, value, files } = event.target;
  
    if (name === "image" && files.length > 0) {
      // If the input is for image and a file is selected
      setUpdateBlogForm({
        ...updateBlogForm,
        [name]: files[0], // Store the selected file in the state
      });
    } else {
      // For other input fields
      setUpdateBlogForm({
        ...updateBlogForm,
        [name]: value,
      });
    }
    
    // Reset error when user starts typing
    setUpdateBlogFormErrors({
      ...updateBlogFormErrors,
      [name]: "", 
    });
  };
  

  const handleDescriptionChange = (value) => {
    setUpdateBlogForm({
      ...updateBlogForm,
      description: value,
    });
    setUpdateBlogFormErrors({
      ...updateBlogFormErrors,
      description: "", // Reset error when user starts typing
    });
  };

  const handleClear = () => {
    setUpdateBlogForm({
      title: "",
      description: "",
      report_date: "",
      blog_type: "",
      author: "",
      image: null,
    });
    setUpdateBlogFormErrors({
      title: "",
      description: "",
      report_date: "",
      blog_type: "",
      author: "",
      image: "",
    });
  };

  const handleSubmit = () => {
    setLoader(true);
    let formData = new FormData();
    formData.append("category", "blog");
  
    // Append form data fields
    formData.append("title", updateBlogForm.title);
    formData.append("description", updateBlogForm.description);
    formData.append("report_date", updateBlogForm.report_date);
    formData.append("blog_type", updateBlogForm.blog_type);
    formData.append("author", updateBlogForm.author);
    
    // Append image file if selected
    if (updateBlogForm.image) {
      formData.append("image", updateBlogForm.image);
    }
  
    axios({
      url: `${baseURL}${updateID.id}`,
      method: "PUT",
      data: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          setReloadPage(reloadPage + 1);
          setShow(false);
          toast.success("Successfully Updated articles");
          setLoader(false);
          setUpdateBlogForm({
            title: "",
            description: "",
            report_date: "",
            blog_type: "",
            author: "",
            image: null,
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setReloadPage(reloadPage + 1);
          setShow(false);
          toast.error("Token is Expired");
          setLoader(false);
          setUpdateBlogForm({
            title: "",
            description: "",
            report_date: "",
            blog_type: "",
            author: "",
            image: null,
          });
        }
      });
  };
  
  const modules = {
    toolbar: {
        container: "#toolbar",
    }
}
const formats = [
  'font','size',
  'bold','italic','underline','strike',
  'color','background',
  'script',
  'header','blockquote','code-block',
  'indent','list',
  'direction','align',
  'link','image','video','formula',
]
  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        transition={Zoom}
        delay={500}
        limit={1}
      />
      <Modal show={showDelete} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Meamber</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Modal.Body>
            <div className="d-flex">
              <div style={{ marginLeft: "50px" }}>
                <Button variant="secondary" onClick={handleDeleteClear}>
                  Cancel
                </Button>
              </div>
              <div style={{ marginLeft: "150px" }}>
                {loader == false && (
                  <Button variant="danger" onClick={handleDeleteSubmit}>
                    Delete
                  </Button>
                )}
                {loader == true && (
                  <Spinner animation="border" variant="Primary" />
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
  <Modal.Header closeButton>
    <Modal.Title>Update Blog</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="col-md-12">
      <Row className="mb-6">
        <Form.Group as={Col}>
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Title"
            name="title"
            value={updateBlogForm.title}
            onChange={handleChange}
            autoComplete="off"
          />
          <span className="text-danger">
            {updateBlogFormErrors.title}
          </span>
        </Form.Group>
      </Row>
      <Row className="mb-6">
        <Form.Group as={Col}>
          <Form.Label>Description</Form.Label>
          <ReactQuill
            theme="snow"
           
            value={updateBlogForm.description}
            onChange={handleDescriptionChange}
            placeholder="Enter description"
          />
          <span className="text-danger">
            {updateBlogFormErrors.description}
          </span>
        </Form.Group>
      </Row>
      <Row className="mb-6">
        <Form.Group as={Col}>
          <Form.Label>Report Date</Form.Label>
          <Form.Control
            type="date"
            name="report_date"
            value={updateBlogForm.report_date}
            onChange={handleChange}
          />
          <span className="text-danger">
            {updateBlogFormErrors.report_date}
          </span>
        </Form.Group>
      </Row>
      <Row className="mb-6">
        <Form.Group as={Col}>
          <Form.Label>Blog Type</Form.Label>
          <Form.Control
            type="text"
            placeholder="Blog Type"
            name="blog_type"
            value={updateBlogForm.blog_type}
            onChange={handleChange}
            autoComplete="off"
          />
          <span className="text-danger">
            {updateBlogFormErrors.blog_type}
          </span>
        </Form.Group>
      </Row>
      <Row className="mb-6">
        <Form.Group as={Col}>
          <Form.Label>Author</Form.Label>
          <Form.Control
            type="text"
            placeholder="Author"
            name="author"
            value={updateBlogForm.author}
            onChange={handleChange}
            autoComplete="off"
          />
          <span className="text-danger">
            {updateBlogFormErrors.author}
          </span>
        </Form.Group>
      </Row>
      <Row className="mb-6">
        <Form.Group as={Col}>
          <Form.Label>Select Image</Form.Label>
          <Form.Control
            type="file"
            placeholder="Select Image"
            name="image"
  
            onChange={handleChange}
            autoComplete="off"
          />
          <span className="text-danger">
            {updateBlogFormErrors.image}
          </span>
        </Form.Group>
      </Row>
    </div>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleClear}>
      Clear
    </Button>
    {loader === false && (
      <Button variant="primary" onClick={handleSubmit}>
        Update
      </Button>
    )}
    {loader === true && <Spinner animation="border" variant="Primary" />}
  </Modal.Footer>
</Modal>

      <div>
        <div class="sidebar">
          <SideBar />
        </div>

        <div class="content">
          <div className="container ">
            <FirstNavbar />

            <div className="row my-2">
              <div className="col-md-3">
                <h3>
                  <b> Blogs List </b>
                </h3>
              </div>
              <div className="col-md-4 text-end">
                <button type="button" className="btn btn-outline-success mx-1">
                  <Link to="/blog-add">Add Blog  </Link>
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success mx-1"
                  disabled
                >
                  <Link to="/leadership-list"> Blogs List</Link>
                </button>
              </div>
            </div>

            <div
              className="ag-theme-alpine"
              style={{ height: "75vh", width: "100%" }}
            >
              <AgGridReact
                rowHeight={rowHeight}
                columnDefs={colDefs}
                defaultColDef={DefaultColumnSetting}
                pagination={true}
                paginationPageSize={10}
                rowData={rowData}
              />{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogList;
